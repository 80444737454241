// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/pages/Queues.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';


const Queues = () => {
  const [bookings, setBookings] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://py-maracar.afaa.website/queues/api/view?Status=1&sort_by=ExpectedStartTime&sort=asc', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        // Process the response to include customer details at the booking level
        const processedData = response.data.map(booking => ({
          ...booking,
          customer_first_name: booking.Queues[0]?.customer_first_name,
          customer_sur_name: booking.Queues[0]?.customer_sur_name,
          customer_telephone: booking.Queues[0]?.customer_telephone,
        }));
        setBookings(processedData);
      } catch (error) {
        console.error('There was an error fetching the booking data:', error);
      }
    };

    fetchBookings();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  

  const employeeNameTemplate = (rowData) => {
    return `${rowData.employee_first_name} ${rowData.employee_sur_name}`;
  };

  const bookingIDTemplate = (rowData) => {
    const handleInvoiceNavigation = () => {
      localStorage.setItem('localBookingReduxID', rowData.BookingID.toString());
      window.open(`/Invoice?BookingID=${rowData.BookingID}`, '_blank');
    };

    return (
      <Button
        label={rowData.BookingID.toString()}
        icon="pi pi-search"
        className="p-button-raised p-button-rounded p-button-success"
        onClick={handleInvoiceNavigation}
      />
    );
  };



  const rowExpansionTemplate = (data) => (
    <DataTable value={data.Queues}>
      <Column field="QueueID" header="Queue ID"></Column>
      <Column field="StatusName" header="Status"></Column>

      
      <Column field="ServiceName" header="Service" sortable filter />
      <Column field="PriceType" header="Type" sortable filter />

      <Column field="CreatedAt" header="Created At" sortable filte></Column>
      
      <Column field="ExpectedStartTime" header="Start Time" body={(rowData) => formatDate(rowData.ExpectedStartTime)} sortable filte ></Column>

      <Column field="ExpectedEndTime" header="End Time" body={(rowData) => formatDate(rowData.ExpectedEndTime)} sortable filte></Column>

      <Column field="employee_first_name" header="Employee" body={employeeNameTemplate} ></Column>




      {/* Additional queue details as needed */}
    </DataTable>
  );

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">
        <Header searchQuery={globalFilter} setSearchQuery={setGlobalFilter} />
        <DataTable value={bookings} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate} dataKey="BookingID"
          paginator rows={20} globalFilter={globalFilter} header="Queues Management">
          <Column expander style={{ width: '3em' }} />

          <Column field="BookingID" header="Booking ID" body={bookingIDTemplate} sortable filter filterPlaceholder="Search by ID"></Column>

          <Column field="BookingID" header="Booking ID" sortable filter />
          <Column field="customer_first_name" header="Customer First Name" sortable filter />
          <Column field="customer_sur_name" header="Customer Surname" sortable filter />
          <Column field="customer_telephone" header="Customer Telephone" sortable filter />
        </DataTable>
      </div>
    </div>
  );
}

export default Queues;
