///var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Payment from './pages/Payment';
import Services from './pages/Services';
import Booking from './pages/Booking';
import Invoice from './pages/Invoice';
import Receipt from './pages/Receipt';
import Member from './pages/Member';
import Logout from './pages/Logout';
import Queues from './pages/Queues';
import Typeservices from './pages/Typeservices';
import ChangePassword from './pages/ChangePassword.js'; // นำเข้า ChangePassword
import MemberMLM from './pages/MemberMLM';


// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Receipt" element={<Receipt />} />
        <Route path="/Invoice" element={<Invoice />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Booking" element={<Booking />} />
        <Route path="/queues" element={<Queues />} />
        <Route path="/Member" element={<Member />} />
        <Route path="/change-password" element={<ChangePassword />} /> {/* เพิ่ม Route สำหรับ ChangePassword */}
        <Route path="/typeservices" element={<Typeservices />} /> {/* Add this line */}
        <Route path="/Logout" element={<Logout />} />
        <Route path="/MemberMLM" element={<MemberMLM />} />
      </Routes>
    </Router>
  );
};

export default App;
