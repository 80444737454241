///var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/utilities/MemberPDFDocument.js

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import notoSerifThaiBase64 from './readFont.js'; // Adjust the import path as necessary

// Register font
Font.register({
  family: 'NotoSerifThai',
  src: notoSerifThaiBase64,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 10,
    fontFamily: 'NotoSerifThai',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  header: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    color: '#000000',
    fontFamily: 'NotoSerifThai'
  },
  subHeader: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'right',
    color: '#666',
    fontFamily: 'NotoSerifThai'
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableColHeader: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#f2f2f2',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'NotoSerifThai',
    wordWrap: 'break-word',
    color: '#333',
    fontSize: 10,
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    textAlign: 'center',
    fontFamily: 'NotoSerifThai',
    wordWrap: 'break-word',
    color: '#666',
    fontSize: 9,
  },
  smallCol: {
    flex: 0.5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    textAlign: 'center',
    fontFamily: 'NotoSerifThai',
    wordWrap: 'break-word',
    color: '#666',
    fontSize: 9,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 3,
    fontSize: 8,
    fontFamily: 'NotoSerifThai'
  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 10,
    color: '#666',
    fontFamily: 'NotoSerifThai'
  }
});

// Create Document Component
const MemberPDFDocument = ({ members }) => {
  const printDate = new Date().toLocaleString();
  const userId = localStorage.getItem('user_ID');
  //onsole.log('PDF Members Data:', members); // Debugging statement to verify data

  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Member Information Report</Text>
      <Text style={styles.subHeader}>Printed By ID: {userId} | Print Date: {printDate}</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.smallCol}>รหัสผู้ใช้</Text>
          <Text style={styles.tableColHeader}>ชื่อ - นามสกุล</Text>
          <Text style={styles.tableColHeader}>อีเมล</Text>
          <Text style={styles.tableColHeader}>โทรศัพท์</Text>
          <Text style={styles.tableColHeader}>วันเกิด</Text>
          <Text style={styles.tableColHeader}>วันที่สมัคร</Text>
          <Text style={styles.tableColHeader}>จังหวัด</Text>
          <Text style={styles.tableColHeader}>Up Line</Text>
          <Text style={styles.tableColHeader}>ตำแหน่ง</Text>
        </View>
        {members.map(member => (
          <View style={styles.tableRow} key={member.user_ID}>
            <Text style={styles.smallCol}>{member.user_ID}</Text>
            <Text style={styles.tableCol}>{member.first_name} {member.sur_name}</Text>
            <Text style={styles.tableCol}>{member.email}</Text>
            <Text style={styles.tableCol}>{member.telephone}</Text>
            <Text style={styles.tableCol}>{new Date(member.birthday).toLocaleDateString()}</Text>
            <Text style={styles.tableCol}>{new Date(member.date).toLocaleDateString()}</Text>
            <Text style={styles.tableCol}>{member.jangwatNameTH}</Text>
            <Text style={styles.tableCol}>{member.upline}</Text>
            <Text style={styles.tableCol}>{member.position}</Text>
          </View>
        ))}
      </View>
      <Text
        style={styles.footer}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
  );
};

export default MemberPDFDocument;
