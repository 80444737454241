// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/pages/Login.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser, clearUser } from '../store/userSlice';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../css/Login.module.scss'; 

const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/Dashboard');
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setShowModal(false);

    if (!phone || !password) {
      setErrorMessage('Please enter both phone and password.');
      setShowModal(true);
      return;
    }

    if (!/^\d+$/.test(phone)) {
      setErrorMessage('Please enter a valid phone number.');
      setShowModal(true);
      return;
    }

    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      setShowModal(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await fetch('https://py-maracar.afaa.website/login/api/check_tel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone, password }),
      });

      const data = await response.json();

      if (!response.ok || data.message !== 'OK') {
        setErrorMessage('Login failed: Incorrect phone or password.');
        setShowModal(true);
        setIsSubmitting(false);
        return;
      }

      dispatch(clearUser());

      localStorage.setItem('token', data.token);
      localStorage.setItem('user_ID', data.user);

      const userResponse = await fetch(`https://py-maracar.afaa.website/register/api/user?user_ID=${data.user}`, {
        headers: {
          'Authorization': `Bearer ${data.token}`,
        },
      });
      const userData = await userResponse.json();

      localStorage.setItem('first_name', userData.first_name);
      localStorage.setItem('sur_name', userData.sur_name);
      localStorage.setItem('image', userData.image);

      dispatch(setUser({
        user: userData,
        positionID: userData.position_ID,
      }));

      navigate('/Dashboard');
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred.');
      setShowModal(true);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles['login-background']}>
      <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <div className={`card shadow-lg p-4 mb-5 bg-white rounded ${styles['card-login']}`} style={{ maxWidth: '400px' }}>
          <div className="card-body">
            <h3 className="text-center mb-4">CMS ADMIN HAPPYDEBTCLINIC</h3>
            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <label htmlFor="staticPhone" className="form-label">เบอร์โทรศัพท์</label>
                <input
                  type="number"
                  className="form-control"
                  id="staticPhone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputPassword" className="form-label">รหัสผ่าน</label>
                <div className="input-group">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="inputPassword"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                  <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                    <i className={showPassword ? 'fa-solid fa-eye-slash' : 'fa-regular fa-eye'}></i>
                  </button>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> Logging in...
                    </>
                  ) : (
                    'LOGIN'
                  )}
                </button>
              </div>
            </form>

            <p className={`text-center mt-5 mb-0 font13 ${styles['font13']}`}>Developed by AFRA APACHE, Version 0.8 (MLM)</p>
          </div>
        </div>

        {showModal && (
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Login Error</h5>
                  <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                </div>
                <div className="modal-body">
                  <p>{errorMessage}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showModal && <div className="modal-backdrop show"></div>}
      </div>
    </div>
  );
};

export default Login;
