// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/index.js or App.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Use client instead of ReactDOM
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import App from './App';

// Get the root element from the DOM
const container = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(container);

// Initial render
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
