// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/components/ServicesForm.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faFloppyDisk, faTimes } from '@fortawesome/free-solid-svg-icons';

import TextEditor from './editor_textarea';
import requestUUID from './request_uuid';
import ToastNotification from './ToastNotification';
import PriceOptions from './PriceOptions';
import ImageForm from './ImageForm';
import GalleryForm from './GalleryForm';

const ServicesForm = ({ serviceData, mode, onCancel, onSave }) => {
  const [uuid, setUuid] = useState('');
  const user_ID = parseInt(localStorage.getItem('user_ID')) || null;

  // Function to handle changes in TextEditor and update the state
  const handleDescriptionChange = (value) => {
    //setFormData({ ...formData, Description: value });
    setFormData(formData => ({ ...formData, Description: value }));
  };

  const handleInfoChange = (value) => {
    //setFormData({ ...formData, Info: value });
    setFormData(formData => ({ ...formData, Info: value }));
  };

  const [formData, setFormData] = useState({
    ServiceID: mode === 'edit' ? serviceData.ServiceID : undefined,
    ServiceName: serviceData?.ServiceName || '',
    Description: serviceData?.Description || '',
    ImageURL: serviceData?.ImageURL || '',
    CategoryID: serviceData?.CategoryID || '',
    Info: serviceData?.Info || '',
    status: serviceData?.status || '',
  });

  // State to manage the dynamic price options
  const [priceOptions, setPriceOptions] = useState([
    {
      PriceType: '',       // Initializes an empty string for PriceType in the price option.
      PriceValue: 0,       // Initializes PriceValue as 0 for the price option.
      currency: 'EUR',     // Sets 'EUR' as the default currency for the price option.
      symbol: '€',         // Sets the symbol '€' for the currency.
      status: '1',          // Initializes an empty string for status in the price option.
      details: 'null',         // Initializes an empty string for details in the price option.
      TableData_ID: '3',    // Initializes an empty string for TableData_ID in the price option.
      priceConn_dataID: formData.ServiceID || '0', // Initializes an empty string for priceConn_dataID in the price option.
      uuid: uuid,
      user_ID: parseInt(user_ID),
    },
  ]);

  const handlePriceOptionChange = (index, e) => {
    const { name, value } = e.target;
    const newPriceOptions = priceOptions.map((option, i) => {
      if (i === index) {
        return {
          ...option,
          [name]: value,
          details: option.details || '', // Ensure details is always a string
          uuid: option.uuid || uuid
        };
      }
      return option;
    });
    setPriceOptions(newPriceOptions);
  };



  // New state for toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('primary'); // You can have different variants like 'success', 'warning', etc.

  const addPriceOption = () => {
    // Assume all options should have PriceType and PriceValue filled
    const isAnyOptionEmpty = priceOptions.some(option => option.PriceType === '' || option.PriceValue === 0);

    // Retrieve user_ID from localStorage or state
    const user_ID = localStorage.getItem('user_ID') || 'defaultUserID'; // ตัวอย่างการรับ user_ID
    const serviceID = formData.ServiceID || 'defaultServiceID'; // ใช้ค่าเริ่มต้นหากไม่มี ServiceID



    // Check if there's a valid UUID before adding a new price option
    if (!uuid) {
      console.error('UUID is not available. Cannot add a new price option.');
      return;
    }

    if (!isAnyOptionEmpty) {

      // Log the price options before the addition
      console.log('Price options before addition:', priceOptions);
      console.log('UUID for price option:', uuid);

      // Add a new price option with the fetched UUID
      setPriceOptions(prevOptions => [
        {
          PriceType: '',
          PriceValue: 0,
          currency: 'EUR',
          symbol: '€',
          status: '1',
          details: 'null',
          TableData_ID: '3',
          priceConn_dataID: formData.ServiceID || '0',
          uuid: uuid, // Include the UUID in the new price option
          PriceID: 0,
          user_ID: user_ID,
        },
        ...prevOptions,
      ]);
      // Log the price options after the addition
      console.log('A new price option has been added. Price options after addition:', priceOptions);


      setToastMessage('A new price option is ready to be added.');
      setToastVariant('success'); // or any other variant you wish to use
      setShowToast(true);
      console.log("Adding new price option:", isAnyOptionEmpty);
    } else {
      // Prevent adding new option and show warning toast
      console.log("Please fill in the last price option before adding a new one.");
      setToastMessage('Please fill in all fields of the existing price options before adding a new one.');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const removePriceOption = (index) => {
    const updatedPriceOptions = [...priceOptions];
    updatedPriceOptions.splice(index, 1); // ลบ price option ที่ต้องการออกจากอาร์เรย์

    // อัปเดต state ด้วยรายการ price options ใหม่ที่ไม่รวม price option ที่ถูกลบ
    setPriceOptions(updatedPriceOptions);
  };


  const [statusOptions, setStatusOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // State ใหม่สำหรับการติดตามการบันทึก
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(serviceData?.ImageURL || '');

  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [triggerAddOption, setTriggerAddOption] = useState(false);

  const [priceData, setPriceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);




  const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };
  const handleImagesChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);

    const newPreviewImages = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise(resolve => {
        reader.onload = () => {
          // ที่นี่เราตั้งค่า isFromDatabase เป็น false สำหรับการอัปโหลดใหม่
          resolve({ url: reader.result, isFromDatabase: false });
        };
      });
    });

    Promise.all(newPreviewImages).then(images => {
      // เพิ่มรูปภาพใหม่ลงในรายการรูปภาพที่มีอยู่แล้ว
      setPreviewImages(prevImages => [...prevImages, ...images]);
    });
  };



  const onImageOrderChange = (e) => {
    setPreviewImages(e.value); // Update the order of preview images
  };


  // Template for OrderList items
  const imageItemTemplate = (image, index) => {
    return (
      <div className="image-list-item">
        <img src={image} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
        {/* Add additional content or actions per image if necessary */}
      </div>
    );
  };




  const uploadImages = async () => {
    const formData = new FormData();


    // รวมการเลือกรูปภาพเดี่ยวและหลายรูปภาพเข้าด้วยกัน
    if (selectedImage) {
      formData.append('uploadFile[]', selectedImage);
    }

    selectedImages.forEach(image => formData.append('uploadFile[]', image));

    // หากไม่มีรูปภาพเพื่ออัพโหลด
    if (!selectedImage && selectedImages.length === 0) {
      return;
    }

    const response = await fetch('https://go.afaa.website/aachat/api/upload', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload image(s).');
    }

    const responseData = await response.json();

    // พิมพ์การตอบกลับ (response)
    console.log(responseData);

    return responseData;
  };

  // อัปเดต formData เมื่อ serviceData มีการเปลี่ยนแปลง
  useEffect(() => {

    const fetchImages = async () => {
      const serviceID = formData.ServiceID || '0';
      try {
        const [response1, response2] = await Promise.all([
          fetch(`https://py-maracar.afaa.website/img/api/view?TableData_ID=${serviceID}&user_ID=${user_ID}`),
          fetch(`https://py-maracar.afaa.website/img/api/view?user_ID=${user_ID}&uuid=${uuid}`),
        ]);

        if (!response1.ok && !response2.ok) {
          if (response1.status === 404 && response2.status === 404) {
            console.error("No images found for the given IDs and UUIDs.");
            setToastMessage('No images in the album.'); // Assuming you have a toast notification system
            setShowToast(true); // Show the toast notification
          } else {
            // Handle other non-ok responses
            throw new Error(`HTTP error! status1: ${response1.status}, status2: ${response2.status}`);
          }
        } else {
          const [data1, data2] = await Promise.all([response1.json(), response2.json()]);

          // Map images with an index and filter out the one with index 0
          const mappedData1 = data1
            .map((img, index) => ({ ...img, isFromDatabase: true, index })) // Add index to each image
            .filter(img => img.index !== 0); // Filter out the one with index 0



          // Merge images from both sources while avoiding duplicates by checking the id
          const mergedImages = [
            ...mappedData1,
            ...data2.map(img => ({ ...img, isFromDatabase: true, index: undefined }))
          ].reduce((uniqueImages, image) => {
            if (!uniqueImages.find(existingImage => existingImage.id === image.id)) {
              uniqueImages.push(image);
            }
            return uniqueImages;
          }, []);

          setPreviewImages(mergedImages); // This will now have images with the new flag
          imagesFetched = true; // Images have been fetched successfully
        }
      } catch (error) {
        console.error("There was a problem fetching image data:", error);
        // Handle errors such as network errors, no response, etc.
      }
    };



    // Call fetchImages within useEffect or as needed

    fetchImages();

    // Fetch and set a UUID when the component mounts
    const fetchUuid = async () => {
      try {
        const fetchedUuid = await requestUUID();
        setUuid(fetchedUuid);
      } catch (error) {
        console.error('Failed to fetch UUID:', error);
      }
    };
    fetchUuid();

    // ดึงข้อมูลสถานะ
    const fetchStatusOptions = async () => {
      const response = await fetch('https://py-maracar.afaa.website/register/api/status_list');
      const data = await response.json();
      setStatusOptions(data);
    };

    // ดึงข้อมูลประเภทบริการ
    const fetchCategoryOptions = async () => {
      try {
        const response = await fetch(`https://py-maracar.afaa.website/services/api/types_view?user_ID=${user_ID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategoryOptions(data);
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    if (serviceData) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ServiceID: serviceData.ServiceID, // อัปเดต ServiceID สำหรับการแก้ไข
        ServiceName: serviceData.ServiceName,
        Description: serviceData.Description,
        ImageURL: serviceData.ImageURL,
        CategoryID: serviceData.CategoryID,
        Info: serviceData.Info,
        status: serviceData.status,
      }));
    }

    if (triggerAddOption) {
      addPriceOption();
      setTriggerAddOption(false); // รีเซ็ต trigger หลังจากเพิ่มตัวเลือกการกำหนดราคา
    }


    // Inside useEffect or a function where you fetch price data
    // Fetch price data
    // Fetch price data
    const fetchPriceData = async () => {
      setIsLoading(true);

      const user_ID = parseInt(localStorage.getItem('user_ID')) || null; // Ensure to parse user_ID
      const serviceID = formData.ServiceID || null; // Use formData.ServiceID if available


      try {
        // Update the fetch URL to include priceConn_dataID and user_ID in the query
        const url = `https://py-maracar.afaa.website/price/api/view?priceConn_dataID=${serviceID}&user_ID=${user_ID}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: commonHeaders,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch price data');
        }

        const jsonData = await response.json();
        // Transform the data if necessary to fit the priceOptions structure
        const transformedData = jsonData.prices.map(price => ({
          PriceID: price.PriceID, // ดึงและแม็ปข้อมูล PriceID
          PriceType: price.PriceType,
          PriceValue: price.PriceValue,
          currency: price.currency,
          status: price.status.toString(), // เปลี่ยนให้เป็นสตริง
          details: "", // เพิ่มค่า details ที่นี่
          TableData_ID: price.TableData_ID,
          priceConn_dataID: price.priceConn_dataID,
          uuid: price.uuid,
          symbol: price.symbol, // เพิ่มค่า symbol ตามค่าใน price
          user_ID: user_ID, // Include user_ID here
        }));

        // Add an empty object for new entries
        transformedData.unshift({
          PriceType: '',
          PriceValue: 0,
          currency: 'EUR',
          symbol: '€',
          status: '1',
          details: 'null',
          TableData_ID: '3',
          priceConn_dataID: formData.ServiceID || '0',
          uuid: uuid, // Include the UUID for the new price option
          user_ID: user_ID, // Include user_ID for new entries as well
        });

        setPriceOptions(transformedData); // Update the state with the transformed data
      } catch (error) {
        console.error("Error fetching price data:", error);
      } finally {
        setIsLoading(false);
      }
    };


    if (priceData) {
      // มีข้อมูลราคา
      console.log('มีข้อมูลราคา:', priceData);
    } else {
      // ไม่มีข้อมูลราคา
      console.log('ไม่มีข้อมูลราคา');
    }


    fetchStatusOptions();
    fetchCategoryOptions();
    fetchPriceData();
  }, [serviceData, triggerAddOption, user_ID]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageRemove = async (imageId) => {
    // Call API to delete the image
    const response = await fetch('https://py-maracar.afaa.website/img/api/delete', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: imageId, user_ID })
    });

    if (response.ok) {
      // Remove the image from the state if the deletion was successful
      setPreviewImages(prevImages => prevImages.filter(img => img.id !== imageId));
    } else {
      console.error('Failed to delete the image.');
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewImage(fileReader.result);
      };
    }
  };

  const updatedPriceOptions = priceOptions.map(option => {
    return { ...option, uuid: uuid || '' }; // Ensure uuid is assigned, replace '' with a fallback if needed
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Starting form submission...");
    setIsSaving(true);
    const user_ID = localStorage.getItem('user_ID');



    if (!uuid) {
      console.error('UUID is not available. Submission aborted.');
      setIsSaving(false);
      return;
    }

    // เพิ่ม uuid ในข้อมูล formData
    formData.uuid = uuid;
    formData.user_ID = user_ID;

    // กรองตัวเลือกราคาที่ถูกต้องเท่านั้น
    const validPriceOptions = priceOptions.filter(option =>
      option.PriceType.trim() !== '' &&
      option.PriceValue > 0 &&
      option.currency &&
      option.status
    ).filter(option => option.PriceID !== 0); // ตรวจสอบเพิ่มเติมเพื่อกรอง PriceID ไม่เท่ากับ 0


    if (validPriceOptions.length < priceOptions.length) {
      console.log('Some price options are incomplete and will not be submitted.');
      setShowToast(true);
      setToastMessage('Incomplete price options detected. Only complete options will be submitted.');
      setToastVariant('warning');
    }

    // Always proceed with image upload regardless of price options validation
    const imageResponse = await uploadImages();
    console.log('Image upload response:', JSON.stringify(imageResponse, null, 2));


    if (imageResponse) {
      const imageUploadDataArray = imageResponse.fileNames.map((fileName, index) => ({
        uuid,
        user_ID: user_ID,
        tabledataID: formData.ServiceID ? Number(formData.ServiceID) : undefined,
        fileNames: [fileName],
        originalFileURLs: [imageResponse.originalFileURLs[index]],
        resizedFileURLs: [imageResponse.resizedFileURLs[index]],
      }));

      const serverResponse = await fetch('https://py-maracar.afaa.website/img/api/upload', {
        method: 'POST',
        headers: commonHeaders,
        body: JSON.stringify(imageUploadDataArray),
      });

      if (!serverResponse.ok) {
        const errorText = await serverResponse.text();
        console.error('Server Response:', errorText);
        throw new Error('Failed to save image data to the database.');
      }
      console.log('Image data saved successfully.');
      formData.ImageURL = imageUploadDataArray[0].resizedFileURLs[0]; // Update form data with image URL
    }


    // Filter out price options with PriceID not equal to 0
    const filteredPriceOptions = validPriceOptions.filter(option => option.PriceID !== 0);

    if (filteredPriceOptions.length === 0) {
      console.error("Error: No valid price options available for submission.");
      setShowToast(true);
      setToastMessage('No valid price options available for submission.');
      setToastVariant('danger');
      setIsSaving(false);
      return;
    }



    // ตรวจสอบ, กรอง, และเตรียมข้อมูล PriceOptions ก่อนส่ง
    const preparedPriceOptions = priceOptions
      .filter(option => {
        const priceValue = String(option.PriceValue).trim(); // แปลงเป็นสตริงและใช้ .trim()
        return priceValue !== '' && parseFloat(priceValue) > 0; // ตรวจสอบไม่เป็นค่าว่างและมากกว่า 0
      })
      .map(option => ({
        ...option,
        PriceValue: parseInt(option.PriceValue, 10), // แปลง PriceValue เป็น int
        details: option.details || '', // ให้ค่าเริ่มต้นเป็นสตริงว่างหากไม่มีค่า
        priceConn_dataID: formData.ServiceID || option.priceConn_dataID, // ใช้ค่า ServiceID จาก formData หรือจาก option
      }));

    // สร้าง finalData ด้วย PriceOptions ที่เตรียมไว้
    const finalData = {
      Prices: preparedPriceOptions,
      uuid,
      user_ID: parseInt(user_ID, 10), // ตรวจสอบให้แน่ใจว่า user_ID ถูกแปลงเป็นตัวเลขอย่างถูกต้อง
    };

    // แยกข้อมูล PriceOptions เพื่อส่งไปยัง API สำหรับการ update และ create
    const priceOptionsToUpdate = preparedPriceOptions.filter(option => option.PriceID > 0);
    const priceOptionsToCreate = preparedPriceOptions.filter(option => !option.PriceID || option.PriceID <= 0);


    // พิมพ์ข้อมูลที่จะส่งไปยัง API create
    if (priceOptionsToCreate.length > 0) {
      console.log('Price options to create:', JSON.stringify(priceOptionsToCreate));
    }

    // กำหนด URL API และ method สำหรับการส่งข้อมูล
    const updateAPIURL = 'https://py-maracar.afaa.website/price/api/update';
    const createAPIURL = 'https://py-maracar.afaa.website/price/api/create';

    // ส่งข้อมูลไปยัง API update หากมีข้อมูลที่ต้องการอัปเดต
    // Submit Updates (if any)
    if (priceOptionsToUpdate.length > 0) {
      const updatePayload = priceOptionsToUpdate.map(option => ({
        ...option,
        PriceValue: parseInt(option.PriceValue, 10) // Ensuring PriceValue is an integer
      }));

      try {
        const response = await fetch(updateAPIURL, {
          method: 'PUT',
          headers: commonHeaders,
          body: JSON.stringify(updatePayload), // Directly sending the array
        });

        if (!response.ok) throw new Error('Failed to update price data.');
        console.log('Update response:', await response.json());
      } catch (error) {
        console.error('Error updating price data:', error);
      }
    }

    // Submit Creations (if any)
    if (priceOptionsToCreate.length > 0) {
      const createPayload = {
        Prices: priceOptionsToCreate.map(option => ({
          ...option,
          PriceValue: parseInt(option.PriceValue, 10) // Ensuring PriceValue is an integer
        }))
      };

      try {
        const response = await fetch(createAPIURL, {
          method: 'POST',
          headers: commonHeaders,
          body: JSON.stringify(createPayload), // Sending with the Prices wrapper
        });

        if (!response.ok) throw new Error('Failed to create price data.');
        console.log('Create response:', await response.json());
      } catch (error) {
        console.error('Error creating price data:', error);
      }
    }

    setIsSaving(false); // Form submission process completed, revert save operation indication



    // Update or save the service with the current form data
    console.log('Saving form data:', JSON.stringify(formData));
    onSave(formData); // Save or update the service with the current form data
    setIsSaving(false); // Reset saving state
  };

  return (
    <div className="">
      {/* คอมโพเนนต์ ToastNotification */}
      <ToastNotification
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
        toastVariant={toastVariant}
      />

      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* Left column for form inputs */}
            <div className="col-md-8">
              {/* Status */}

              {/* Status */}
              <div className="mb-3">
                <label htmlFor="status" className="form-label">Status:</label>
                <select
                  id="status"
                  name="status"
                  className="form-control"
                  value={formData.status || ''}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a Status</option>
                  {statusOptions.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>


              {/* Category ID */}
              <div className="mb-3">
                <label htmlFor="CategoryID" className="form-label">Category:</label>
                <select
                  id="CategoryID"
                  name="CategoryID"
                  className="form-control"
                  value={formData.CategoryID || ''}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a Category</option>
                  {categoryOptions.map(option => (
                    <option key={option.ServiceTypes_ID} value={option.ServiceTypes_ID}>
                      {option.ServiceTypes_Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Service Name */}
              <div className="mb-3">
                <label htmlFor="ServiceName" className="form-label">Service Name:</label>
                <input
                  type="text"
                  id="ServiceName"
                  name="ServiceName"
                  className="form-control"
                  value={formData.ServiceName || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Price Details Table */}

              {/* Within ServicesForm.js component's return statement */}
              <PriceOptions
                priceOptions={priceOptions}
                priceData={priceData}
                handlePriceOptionChange={handlePriceOptionChange}
                addPriceOption={addPriceOption}
                setPriceOptions={setPriceOptions}
                removePriceOption={removePriceOption}
                statusOptions={statusOptions} // Make sure you've fetched and stored the status options in state
              />

              {/* Description */}
              <div className="mb-3">
                <label htmlFor="Description" className="form-label">Description:</label>

                <TextEditor
                  value={formData.Description}
                  onContentChange={handleDescriptionChange}
                />
              </div>

              {/* Additional Info */}
              <div className="mb-3">
                <label htmlFor="Info" className="form-label">Additional Info:</label>
                <TextEditor
                  value={formData.Info}
                  onContentChange={handleInfoChange}
                />
              </div>

            </div>

            {/* Right column for image and gallery */}
            <div className="col-md-4">


              {isSaving && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {/* Submit and Cancel buttons */}
              <div className="d-grid gap-2 d-md-flex justify-content-md-end pa-top-10">

                <button type="button" onClick={onCancel} className="btn btn-light">
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>

                <button type="submit" className="btn btn-dark">
                  <FontAwesomeIcon icon={faFloppyDisk} /> {mode === 'add' ? 'Add' : 'Update'} Service
                </button>

              </div>

              <ImageForm
                selectedImage={selectedImage}
                previewImage={previewImage}
                handleImageChange={handleImageChange}
              />


              {/* Image URL */}
              <div className="mb-3">
                <label htmlFor="ImageURL" className="form-label">Image URL:</label>
                <input
                  type="text"
                  id="ImageURL"
                  name="ImageURL"
                  className="form-control"
                  value={formData.ImageURL || ''}
                  onChange={handleChange}
                />
              </div>

              <GalleryForm
                selectedImages={selectedImages}
                previewImages={previewImages}
                handleImageRemove={handleImageRemove}
                handleImagesChange={handleImagesChange}
                onImageOrderChange={onImageOrderChange}
                imageItemTemplate={imageItemTemplate}
              />

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ServicesForm;
