// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/MainContent.js

import React from 'react';
import StatsWidget from '../pages/StatsWidget'; // Adjust the path as needed


import '../css/MainContent.css'; // Make sure this path is correct

const MainContent = () => {
  return (
    <main className="main-content">
      <StatsWidget />     
      {/* Additional components like charts, tables, etc */}   
    </main>
  );
};

export default MainContent;
