import React, { useEffect, useRef } from 'react';
import CanvasJS from '@canvasjs/charts';
import monthlySalesData from '../data/monthlySalesData.json'; // Import monthly sales data
import dailySalesData from '../data/dailySalesData.json'; // Import monthly sales data

const StatsWidget = () => {
  const monthlyChartContainerRef = useRef(null);
  const dailyChartContainerRef = useRef(null);

  useEffect(() => {
    // Define colorSet before the charts initialization
    CanvasJS.addColorSet("customColorSet", [
      "#FF6384", // Color for Income
      "#36A2EB", // Color for Expenses
      // Add more colors as needed
    ]);

    if (monthlyChartContainerRef.current && dailyChartContainerRef.current) {
      // Initialize monthly chart
      const monthlyChartOptions = {
        theme: "light2",
        exportEnabled: true,
        animationEnabled: true,
        title: {
          text: "Monthly Sales Summary"
        },
        colorSet: "customColorSet",
        data: [{
          type: "pie",
          startAngle: 75,
          toolTipContent: "<b>{label}</b>: ${y}",
          showInLegend: true,
          legendText: "{label}",
          indexLabel: "{label} - ${y}",
          indexLabelFontSize: 16,
          dataPoints: monthlySalesData
        }]
      };
      const monthlyChart = new CanvasJS.Chart(monthlyChartContainerRef.current, monthlyChartOptions);
      monthlyChart.render();

      // You need to import or define dailySalesData here if it's supposed to be different from monthlySalesData

      // Initialize daily chart
      const dailyChartOptions = {
        theme: "light2",
        exportEnabled: true,
        animationEnabled: true,
        title: {
          text: "Daily Sales Summary"
        },
        colorSet: "customColorSet",
        // Use dailySalesData here or import it correctly
        data: [{
          type: "pie",
          startAngle: 75,
         
          showInLegend: true,
          legendText: "{label}",
          indexLabel: "{label} - ${y}",
          indexLabelFontSize: 16,
          dataPoints: dailySalesData // Use dailySalesData here
        }]
      };
      const dailyChart = new CanvasJS.Chart(dailyChartContainerRef.current, dailyChartOptions);
      dailyChart.render();

      // Cleanup function
      return () => {
        if (monthlyChart) monthlyChart.destroy();
        if (dailyChart) dailyChart.destroy();
      };
    }
  }, []);

  return (
    <div className="stats-widget">

      <div className="row">
        <div className="col-md-6">
          {/* ... */}

          <div className="stats-widget-section">         
            <div ref={monthlyChartContainerRef} style={{ height: '300px', width: '100%' }} />
          </div>

        </div>
        <div className="col-md-6">
          {/* ... */}

          <div className="stats-widget-section">          
            <div ref={dailyChartContainerRef} style={{ height: '300px', width: '100%' }} />
          </div>

        </div>
      </div>





    </div>
  );
};

export default StatsWidget;
