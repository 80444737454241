// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/components/Header.js

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from 'primereact/toast';

import {
  faCircleLeft, faRotateRight,
  faTachometerAlt, faCreditCard, faCalendarCheck, faPeopleGroup,
  faUsers, faSpa, faLayerGroup, faSignOutAlt, faBell, faPlus
} from '@fortawesome/free-solid-svg-icons';

import '../css/Header.scss';
import '../css/Header.css';

const Header = ({ onBack, searchQuery, setSearchQuery, placeholderText, onRefresh, refreshData, handleAddMember }) => {
  const navigate = useNavigate();
  const op = useRef(null); // For OverlayPanel
  const [bookingNotifications, setBookingNotifications] = useState([]);
  const token = localStorage.getItem('token');
  const [notificationCount, setNotificationCount] = useState(0); // New state for badge count
  const [userOptions, setUserOptions] = useState([]);
  const userId = localStorage.getItem('user_ID');
  const toast = useRef(null);
  const positionID = useSelector((state) => state.user.positionID);

  const handleBack = () => {
    if (onBack) {
      onBack(); // If onBack is provided, call it
    } else {
      navigate(-1); // Otherwise, navigate back
    }
  };

  const customerTemplate = (rowData) => {
    return `${rowData.CustomerFirstName} ${rowData.CustomerSurName}, ${rowData.CustomerTelephone}`;
  };

  const dateTimeTemplate = (rowData) => {
    return `${new Date(rowData.ServiceDate).toLocaleDateString('en-GB')} at ${rowData.ServiceTime.substr(0, 5)}`;
  };

  const renderIcon = (iconData) => {
    return <FontAwesomeIcon icon={iconData} className="p-menuitem-icon" />;
  };

  const allMenuItems = [
    handleAddMember ? {
      label: 'Add Member',
      icon: faPlus,
      command: handleAddMember
    } : null,
    { label: 'MLM Organization', icon: faUsers, command: () => navigate('/MemberMLM') },
    { label: 'Staff', icon: faUsers, command: () => navigate('/Member') },
    { label: 'Services', icon: faSpa, command: () => navigate('/services') },
    { label: 'Type Services', icon: faLayerGroup, command: () => navigate('/typeservices') },
    { label: 'Logout', icon: faSignOutAlt, command: () => navigate('/logout') },
  ].filter(Boolean); // filter out null values

  const restrictedMenuItems = [
    { label: 'Dashboard', icon: faTachometerAlt, command: () => navigate('/Dashboard') },
    { label: 'MLM Organization', icon: faUsers, command: () => navigate('/MemberMLM') },
    { label: 'Staff', icon: faUsers, command: () => navigate('/Member') },
    { label: 'Logout', icon: faSignOutAlt, command: () => navigate('/logout') },
  ];

  const menuItems = (positionID === 3 || positionID === 4) ? allMenuItems : restrictedMenuItems;

  const getMenubarItems = () => menuItems.map(item => ({
    ...item,
    icon: renderIcon(item.icon)
  }));

  const handleNotificationClick = (e) => {
    op.current.toggle(e);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (location.pathname === '/Member') {
      // Handle search functionality specific to the Member page
      // Add any additional search logic here if necessary
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <header className="header">
        <FontAwesomeIcon className="fas back_button" onClick={handleBack} icon={faCircleLeft} />

        <input
          type="text"
          placeholder={placeholderText || "Type in to search..."}
          className="search-bar"
          value={searchQuery}
          onChange={handleSearch}
        />

        <div className="header-icons">
          <FontAwesomeIcon className='fas' icon={faRotateRight} onClick={onRefresh} />
          <i className="fas fa-bell p-overlay-badge" onClick={handleNotificationClick}>
            <Badge value={notificationCount} severity="danger"></Badge>
          </i>

          <OverlayPanel ref={op} id="overlay_panel" style={{ width: '450px' }} className="overlay-panel-scroll">
            <DataTable value={bookingNotifications} size="small" className="custom-small-datatable p-datatable-sm" scrollable scrollHeight="500px">
              <Column field="BookingDetailID" header="ID" sortable />
              <Column header="Date & Time" body={dateTimeTemplate} />
              <Column field="CombinedService" header="Service" sortable />
              <Column header="Customer" body={customerTemplate} />
              <Column field="StatusName" header="Status" sortable />
            </DataTable>
          </OverlayPanel>

          <i className="fas fa-solid fa-sliders"></i>
        </div>
      </header>

      <Menubar model={getMenubarItems()} />
    </>
  );
};

export default Header;
