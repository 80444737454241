import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Ensure this import is added
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUsersGear, faPeopleArrows, faKey, faCopy, faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode.react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MemberPDFDocument from '../utilities/MemberPDFDocument.js';
import * as XLSX from 'xlsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import useFetchStatuses from './useFetchStatuses';
import '../css/MemberTable.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../css/MemberTableTransitions.scss';
import { v4 as uuidv4 } from 'uuid'; // Ensure this import is added

const MemberTable = ({ memberData, onEditMember, token, totalMembers, setTotalMembers, onPageChange, currentPage, setCurrentPage }) => {
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState({});
  const [selectedStatuses, setSelectedStatuses] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeValue, setQRCodeValue] = useState('');
  const { statuses, isLoading } = useFetchStatuses(token);
  const [filters, setFilters] = useState({
    first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    sur_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    telephone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    contact: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [jangwatData, setJangwatData] = useState({});
  const [loadingJangwatData, setLoadingJangwatData] = useState({});
  const [pdfLoading, setPdfLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Added this state
  const pdfLoadingRef = useRef(pdfLoading);
  const prevPdfLoadingRef = useRef(pdfLoading);
  const positionID = useSelector((state) => state.user.positionID);

  // Initializing rowsPerPage and currentPage with default values if not provided
  useEffect(() => {
    if (currentPage === undefined) setCurrentPage(1);
  }, [currentPage, setCurrentPage]);

  useEffect(() => {
    if (pdfLoadingRef.current !== pdfLoading) {
      pdfLoadingRef.current = pdfLoading;
    }
  }, [pdfLoading]);

  useEffect(() => {
    const timer = setInterval(() => {
      const downloadLink = document.querySelector(".pdf-download-link");
      if (downloadLink) {
        const loading = downloadLink.getAttribute("data-loading") === "true";
        if (pdfLoadingRef.current !== loading) {
          setPdfLoading(loading);
          pdfLoadingRef.current = loading;
        }
      }
    }, 100);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await fetch('https://py-maracar.afaa.website/register/api/position_list');
        const data = await response.json();
        setPositions(data);
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    };
    fetchPositions();
  }, []);

  useEffect(() => {
    console.log("Member Data:", memberData); // Log the member data for inspection

    const updatedMemberData = memberData.map(member => ({
      ...member,
      memberData_user_ID: member.user_ID
    }));

    const initialPositions = updatedMemberData.reduce((acc, member) => ({
      ...acc,
      [member.memberData_user_ID]: member.position_ID,
    }), {});
    setSelectedPositions(initialPositions);

    const initialStatuses = updatedMemberData.reduce((acc, member) => ({
      ...acc,
      [member.memberData_user_ID]: member.status_ID,
    }), {});
    setSelectedStatuses(initialStatuses);
  }, [memberData]);

  const fetchJangwatData = useCallback(async (jangwatID) => {
    setLoadingJangwatData(prev => ({ ...prev, [jangwatID]: true }));
    try {
      const response = await fetch(`https://py-maracar.afaa.website/api/jangwat/jangwat?jangwatID=${jangwatID}`, { mode: 'cors' });
      if (response.ok) {
        const data = await response.json();
        setJangwatData(prev => ({
          ...prev,
          [jangwatID]: data[0]?.jangwatNameTH || 'N/A'
        }));
      } else {
        throw new Error(`Failed to fetch jangwat data: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching jangwat data:', error);
    } finally {
      setLoadingJangwatData(prev => ({ ...prev, [jangwatID]: false }));
    }
  }, []);

  useEffect(() => {
    const uniqueJangwatIDs = [...new Set(memberData.map(member => member.jangwatID))];
    uniqueJangwatIDs.forEach(jangwatID => {
      if (jangwatID) {
        fetchJangwatData(jangwatID);
      }
    });
  }, [memberData, fetchJangwatData]);

  const renderStatusDropdown = (userId) => {
    const currentStatusId = selectedStatuses[userId];
    const currentStatusName = statuses.find(s => s.id === currentStatusId)?.name || "Select Status";
    if (![3, 4].includes(positionID)) {
      return <span>{currentStatusName}</span>;
    }
    return (
      <div className="dropdown">
        <button className="btn btn-warning dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <FontAwesomeIcon icon={faPeopleArrows} /> {currentStatusName}
        </button>
        <ul className="dropdown-menu">
          {statuses.map((status) => (
            <li key={status.id}>
              <a className="dropdown-item" href="#" onClick={(event) => {
                event.preventDefault();
                handleStatusUpdate(userId, status.id);
              }}>
                {status.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleRoleUpdate = async (userId, newPositionId) => {
    try {
      const response = await fetch(`https://py-maracar.afaa.website/register/api/position_set?user_ID=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ position_ID: newPositionId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update position');
      }

      setSelectedPositions(prev => ({ ...prev, [userId]: newPositionId }));
    } catch (error) {
      console.error('Error updating position:', error);
    }
  };

  const renderPositionDropdown = (userId) => {
    const selectedPositionId = selectedPositions[userId];
    const currentPosName = positions.find(p => p.position_ID === selectedPositionId)?.position_Name || "Select Position";
    if (![3, 4].includes(positionID)) {
      return <span>{currentPosName}</span>;
    }
    return (
      <div className="dropdown">
        <button className="btn btn-secondary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <FontAwesomeIcon icon={faUsersGear} /> {currentPosName}
        </button>
        <ul className="dropdown-menu">
          {positions.map((position) => (
            <li key={position.position_ID}>
              <a className="dropdown-item" href="#" onClick={(event) => {
                event.preventDefault();
                handleRoleUpdate(userId, position.position_ID);
              }}>
                {position.position_Name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleStatusUpdate = async (userId, newStatusId) => {
    try {
      const response = await fetch(`https://py-maracar.afaa.website/register/api/update_status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_ID: userId, status_ID: newStatusId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }

    setSelectedStatuses(prev => ({ ...prev, [userId]: newStatusId }));
  };

  const navigate = useNavigate();  // Initialize the navigate function from react-router-dom
  const handleChangePassword = (userId) => {
    navigate(`/change-password?userId=${userId}`);
  };

  const handleQRCodeClick = (userId) => {
    const fullUrl = `https://happydebtclinic.com/register?ss=${userId}`;
    setQRCodeValue(fullUrl);
    setShowQRCode(true);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(qrCodeValue).then(() => {
      alert('URL copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
    });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(memberData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Members');
    XLSX.writeFile(workbook, 'members.xlsx');
  };

  const formattedMemberData = useMemo(() =>
    memberData.map(member => ({
      ...member,
      position: positions.find(p => p.position_ID === member.position_ID)?.position_Name || 'N/A',
      status: statuses.find(s => s.id === member.status_ID)?.name || 'N/A',
      upline: `${member.sponsor_first_name} ${member.sponsor_sur_name} (ID: ${member.sponsor_id})`,
      jangwatNameTH: jangwatData[member.jangwatID] || 'N/A',
      uniqueKey: uuidv4() // Add unique key here
    })),
    [memberData, positions, statuses, jangwatData]
  );

  const getRowClassName = (positionId) => {
    const currentPosName = positions.find(p => p.position_ID === positionId)?.position_Name;
    const positionClass = currentPosName ? `position-${currentPosName.replace(/ /g, '-').toLowerCase()}` : '';
    return positionClass;
  };

  useEffect(() => {
    if (prevPdfLoadingRef.current !== pdfLoading) {
      prevPdfLoadingRef.current = pdfLoading;
    }
  }, [pdfLoading]);


  const getPageLinkSize = () => {
    return 5; // Set the number of page links to display
  };

  // Define the function to handle user ID click and navigate to the MemberMLM page
  const handleUserIdClick = (user_id) => {
    navigate(`/MemberMLM?userId=${user_id}`);
  };

  return (
    <div className="table-responsive">
      <div className="mb-3">
      <PDFDownloadLink
  className="pdf-download-link"
  document={<MemberPDFDocument members={formattedMemberData} />}
  fileName="members.pdf"
>
  {({ loading }) => {
    return (
      <Button variant="danger" data-loading={loading}>
        <FontAwesomeIcon icon={faFilePdf} /> {loading ? 'Generating PDF...' : 'Export to PDF'}
      </Button>
    );
  }}
</PDFDownloadLink>


        <Button variant="success" onClick={exportToExcel} className="ms-2">
          <FontAwesomeIcon icon={faFileExcel} /> Export to Excel
        </Button>
      </div>

      <div className="data-table">
        <DataTable
          value={formattedMemberData}
          paginator
          rows={rowsPerPage}
          totalRecords={totalMembers}
          dataKey="memberData_user_ID"
          filters={filters}
          filterDisplay="menu"
          loading={isLoading || pdfLoading}
          globalFilterFields={['first_name', 'sur_name', 'email', 'telephone', 'contact', 'jangwatNameTH']}
          emptyMessage="No members found."
          responsiveLayout="scroll"
          rowClassName={(data) => getRowClassName(data.position_ID)}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate={`Showing {first} to {last} of {totalRecords} members (Page ${currentPage} of ${Math.ceil(totalMembers / rowsPerPage)})`}
          first={(currentPage - 1) * rowsPerPage}
          onPage={(e) => {
            const newPage = Math.floor(e.first / rowsPerPage) + 1;
            onPageChange(newPage);
            setCurrentPage(newPage);
          }}
          pageLinkSize={5} // Directly set the number of page links to display
          onRowsPerPageChange={(e) => setRowsPerPage(e.value)}
        >
          <Column field="user_ID" header="ID" sortable filter filterPlaceholder="Search by User ID" className="custom-column-short"
            body={(rowData) => (
              <span
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => handleUserIdClick(rowData.user_ID)}
              >
                {rowData.user_ID}
              </span>
            )}
            key={uuidv4()}
          />
          <Column field="first_name" header="First Name" sortable filter filterPlaceholder="Search by first name" className="custom-column-short" key={uuidv4()} />
          <Column field="sur_name" header="Sur Name" sortable filter filterPlaceholder="Search by sur name" className="custom-column-short" key={uuidv4()} />
          <Column field="email" header="Email" filter sortable filterPlaceholder="Search by email" className="custom-column" key={uuidv4()} />
          <Column field="telephone" header="Telephone" sortable filter filterPlaceholder="Search by telephone" className="custom-column" key={uuidv4()} />
          <Column field="birthday" header="Birthday" sortable body={(rowData) => new Date(rowData.birthday).toLocaleDateString()} style={{ minWidth: '10rem' }} className="custom-column" key={uuidv4()} />
          <Column field="date" header="Date Register" sortable body={(rowData) => new Date(rowData.date).toLocaleDateString()} style={{ minWidth: '10rem' }} className="custom-column" key={uuidv4()} />
          <Column field="jangwatID" header="Province" body={(rowData) => (
            loadingJangwatData[rowData.jangwatID] ? <ProgressSpinner style={{ width: '20px', height: '20px' }} /> :
              jangwatData[rowData.jangwatID] || 'N/A'
          )} key={uuidv4()} />
          <Column field="contact" header="Contact" sortable filter filterPlaceholder="Search by contact" className="custom-column-contact" key={uuidv4()} />
          <Column
            field="upline"
            header="Up Line"
            sortable
            body={(rowData) => (
              <span>{`${rowData.sponsor_first_name} ${rowData.sponsor_sur_name} (ID: ${rowData.sponsor_id})`}</span>
            )}
            className="custom-column-upline"
            key={uuidv4()}
          />
          <Column header="Position" body={(rowData) => renderPositionDropdown(rowData.memberData_user_ID)} style={{ width: '10rem', textAlign: 'center' }} key={uuidv4()} />
          <Column header="Status" body={(rowData) => renderStatusDropdown(rowData.memberData_user_ID)} style={{ width: '5rem', textAlign: 'center' }} key={uuidv4()} />
          <Column header="QRCode" body={(rowData) => (
            <QRCode value={`https://happydebtclinic.com/register?ss=${rowData.memberData_user_ID}`} size={32} onClick={() => handleQRCodeClick(rowData.memberData_user_ID)} style={{ cursor: 'pointer' }} />
          )} style={{ width: '5rem', textAlign: 'center' }} key={uuidv4()} />
          <Column header="Edit" body={(rowData) => (
            <button className="btn btn-primary me-2 btn-sm" onClick={() => onEditMember(rowData)}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          )} style={{ width: '5rem', textAlign: 'center' }} key={uuidv4()} />
          <Column header="Password" body={(rowData) => (
            <button className="btn btn-info me-2 btn-sm" onClick={() => handleChangePassword(rowData.memberData_user_ID)}>
              <FontAwesomeIcon icon={faKey} />
            </button>
          )} style={{ width: '5rem', textAlign: 'center' }} key={uuidv4()} />
        </DataTable>

        <div className="total-members-count">
          Total Members: {totalMembers}
        </div>
      </div>

      <Modal show={showQRCode} onHide={() => setShowQRCode(false)}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <QRCode value={qrCodeValue} size={256} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCopyClick}>
            <FontAwesomeIcon icon={faCopy} /> Copy URL
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MemberTable;
