// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/components/MemberForm.js

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';


// A simple spinner component
const Spinner = () => (
  <div className="spinner-border text-primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

let globalUserTeamId = null; // Global variable for sponsor.user_team_id
let globalSponsorEdit = null;

const MemberForm = ({ memberData = {}, mode, setIsFormVisible, onTableRefresh }) => {
  const [users, setUsers] = useState([]); // State to store users data
  const localStorage_user_ID = parseInt(localStorage.getItem('user_ID')) || null;
  const [selectedUser, setSelectedUser] = useState(null);

  const [selectedJangwat, setSelectedJangwat] = useState(null);
  const [selectedAmphure, setSelectedAmphure] = useState(null);
  const [selectedThamboon, setSelectedThamboon] = useState(null);

  const [jangwats, setJangwats] = useState([]);
  const [amphures, setAmphures] = useState([]);
  const [thamboons, setThamboons] = useState([]);

  const [formData, setFormData] = useState({
    first_name: '',
    sur_name: '',
    email: '',
    telephone: '',
    birthday: '',
    contact: '',
    image: '',
    social_media: ''
  }); // State to store form data
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ success: false, message: '', loading: false });
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(memberData?.image || '');

  const positionID = useSelector((state) => state.user.positionID); // Get positionID from Redux store


  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {

    if (mode === 'edit') {
      console.log('memberData:', memberData); // Log memberData when in edit mode
    }
    // Fetch users for dropdown
    const fetchUsers = async () => {
      try {
        const response = await fetch(`https://py-maracar.afaa.website/register/api/all_users?user_ID=${localStorage_user_ID}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data.map(user => ({ label: `${user.first_name} ${user.sur_name} - ID: ${user.user_ID}`, value: user.user_ID })));
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    // Fetch sponsor details if in edit mode
    const fetchSponsor = async () => {
      if (mode === 'edit' && memberData?.user_ID) {
        try {
          const response = await fetch(`https://py-maracar.afaa.website/mlm/api/view?user_id=${memberData.user_ID}&sort_order=asc`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          });
          if (!response.ok) throw new Error(`HTTP status ${response.status}: Failed to fetch sponsor details`);
          const data = await response.json();
          const sponsor = data.find(item => item.user_id === memberData.user_ID);

          if (sponsor) {
            console.log('Sponsor ID:', sponsor.sponsor_id);
            console.log('User Team ID:', sponsor.user_team_id);

            // Assign global variable
            globalUserTeamId = sponsor.user_team_id;

            const selectedUserObject = { label: `${sponsor.first_name} ${sponsor.sur_name} - ID: ${sponsor.sponsor_id}`, value: sponsor.sponsor_id, user_ID: sponsor.sponsor_id };
            setSelectedUser(selectedUserObject);
            setUsers(prevUsers => [...prevUsers, selectedUserObject]);
          }

        } catch (error) {
          console.error('Fetch sponsor error:', error.message);
        }
      }
    };
    fetchSponsor();
  }, [memberData?.user_ID, mode]);

  useEffect(() => {
    if (memberData) {
      setFormData({
        first_name: memberData.first_name || '',
        sur_name: memberData.sur_name || '',
        email: memberData.email || '',
        telephone: memberData.telephone || '',
        birthday: memberData.birthday ? getFormattedDate(memberData.birthday) : '',
        contact: memberData.contact || '',
        image: memberData.image || '',
        social_media: memberData.social_media || ''
      });
      setSelectedJangwat(memberData.jangwatID || null);
      setSelectedAmphure(memberData.amphoeID || null);
      setSelectedThamboon(memberData.thamboonID || null);
    }
  }, [memberData]);


  useEffect(() => {
    // Fetch Jangwats for dropdown
    const fetchJangwats = async () => {
      try {
        const response = await fetch('https://py-maracar.afaa.website/api/jangwat/jangwat');
        if (response.ok) {
          const data = await response.json();
          setJangwats(data.map(jangwat => ({ label: jangwat.jangwatNameTH, value: jangwat.jangwatID })));
        }
      } catch (error) {
        console.error('Error fetching Jangwats:', error);
      }
    };
    fetchJangwats();
  }, []);

  useEffect(() => {
    if (selectedAmphure || (memberData && memberData.amphoeID)) {
      handleAmphureChange({ value: selectedAmphure || memberData.amphoeID });
    }
  }, [selectedAmphure, memberData ? memberData.amphoeID : null]);


  
  
  const handleJangwatChange = async (e) => {
    const jangwatID = e.value;
    setSelectedJangwat(jangwatID);
  
    // Fetch Amphures based on selected Jangwat
    try {
      const response = await fetch(`https://py-maracar.afaa.website/api/jangwat/search?jangwatID=${jangwatID}`);
      if (response.ok) {
        const data = await response.json();
        console.log('Amphures data for Jangwat ID', jangwatID, ':', data);
  
        // Ensure unique amphureNameTH values
        const uniqueAmphures = Array.from(new Set(data.map(amphure => amphure.amphureNameTH)))
          .map(name => data.find(amphure => amphure.amphureNameTH === name));
  
        setAmphures(uniqueAmphures.map(amphure => ({ label: amphure.amphureNameTH, value: amphure.amphureID })));
        setSelectedAmphure(null);
        setThamboons([]);
      }
    } catch (error) {
      console.error('Error fetching Amphures:', error);
    }
  };

  const handleAmphureChange = async (e) => {
    const amphureID = e.value;
    const jangwatID = selectedJangwat;
    setSelectedAmphure(amphureID);
  
    // Fetch Thamboons based on selected Amphure and Jangwat
    try {
      const response = await fetch(`https://py-maracar.afaa.website/api/jangwat/search?amphureID=${amphureID}&jangwatID=${jangwatID}`);
      if (response.ok) {
        const data = await response.json();
        console.log('Thamboons data for Amphure ID', amphureID, 'and Jangwat ID', jangwatID, ':', data);
        setThamboons(data.map(thamboon => ({ label: thamboon.thamboonNameTH, value: thamboon.thamboonID })));
        setSelectedThamboon(memberData.thamboonID || null); // Set initial Thamboon from memberData or reset to null
      }
    } catch (error) {
      console.error('Error fetching Thamboons:', error);
    }
  };

  const handleThamboonChange = (e) => {
    const thamboonID = e.value;
    setSelectedThamboon(thamboonID);
    console.log('Selected Thamboon ID:', thamboonID);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewImage(fileReader.result);
      };
    }
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('uploadFile', selectedImage);
    formData.append('user_ID', memberData.user_ID);
    setSubmitStatus(prevStatus => ({ ...prevStatus, loading: true }));

    try {
      const response = await fetch('https://go.afaa.website/aachat/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to upload image.');

      const imageResponse = await response.json();
      setSubmitStatus(prevStatus => ({ ...prevStatus, loading: false }));
      return imageResponse;
    } catch (error) {
      console.error('Image upload failed:', error);
      setSubmitStatus({ success: false, message: 'Failed to upload image.', loading: false });
      throw error;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    if (!selectedUser) {
      setSubmitStatus({ success: false, message: 'Please select a sponsor.', loading: false });
      setIsSubmitting(false);
      return;
    }
  
    let updatedFormData = { ...formData };
  
    if (selectedImage) {
      try {
        const imageResponse = await uploadImage();
        updatedFormData.image = imageResponse.resizedFileURLs[0];
      } catch (error) {
        console.error('Image upload failed:', error);
        setSubmitStatus({ success: false, message: 'Failed to upload image.', loading: false });
        setIsSubmitting(false);
        return;
      }
    }
  
    const currentTime = new Date().toTimeString().split(' ')[0];
    const dataToSend = {
      ...updatedFormData,
      date: new Date().toISOString().split('T')[0],
      time: currentTime,
      ip: "192.168.1.15",
      user_ID: memberData?.user_ID || '',
      jangwatID: selectedJangwat,
      amphoeID: selectedAmphure,
      thamboonID: selectedThamboon,
    };
  
    console.log('Data to send:', dataToSend);
  
    const apiURL = mode === 'edit'
      ? `https://py-maracar.afaa.website/register/api/edit?user_ID=${memberData?.user_ID || ''}`
      : 'https://py-maracar.afaa.website/register/api/insert';
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });
  
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
      const result = await response.json();
  
      // Conditional execution based on mode
      if (mode !== 'edit') {
        if (result.user_id) {
          await addMemberToMLM(result.user_id, selectedUser.user_ID);
        } else {
          throw new Error('API did not return a User ID on insertion.');
        }
      }
  
      setSubmitStatus({ success: true, message: 'Successfully Recorded!', loading: false });
      setIsSubmitting(false);
      onTableRefresh();
  
      if (mode !== 'edit') {
        setFormData({
          first_name: '',
          sur_name: '',
          email: '',
          telephone: '',
          birthday: '',
          contact: '',
          image: '',
          social_media: ''
        });
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setSubmitStatus({ success: false, message: 'Member Record Failed. Please Try Again.', loading: false });
      setIsSubmitting(false);
    }
  };
  

  const addMemberToMLM = async (userId, sponsorId) => {
    console.log('addMemberToMLM userId:', userId);
    console.log('addMemberToMLM sponsorId:', sponsorId);
    console.log('globalSponsorEdit:', globalSponsorEdit);

    const mlmData = [{
      user_id: userId,
      sponsor_id: globalSponsorEdit,
      team_id: 'A1',
      position: 1,
      commission_rate: 6.02,
    }];

    console.log('mlmData:', mlmData);

    const token = localStorage.getItem('token');
    const mlmURL = 'https://py-maracar.afaa.website/mlm/api/insert';
    try {
      const mlmResponse = await fetch(mlmURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(mlmData),
      });

      if (!mlmResponse.ok) throw new Error(`Failed to add user to MLM with status: ${mlmResponse.status}`);

      const mlmResult = await mlmResponse.json();
      setSubmitStatus({ success: true, message: 'Registration and MLM addition successful!', loading: false });
      setIsFormVisible(false);
    } catch (error) {
      console.error('MLM Addition Error:', error);
      setSubmitStatus({ success: false, message: error.message, loading: false });
    }
  };

  const handleUserChange = async (e) => {
    const sponsorId = e.value;
    const selectedUserObject = users.find(user => user.value === sponsorId);
    setSelectedUser(selectedUserObject);
    globalSponsorEdit = sponsorId;

   //console.log('Selected sponsorId:', sponsorId);

    if (mode === 'edit') {
      console.log('Global Sponsor User Team ID:', globalUserTeamId);
      const updateData = [{
        user_team_id: globalUserTeamId, // Existing user ID
        new_user_team_id: memberData.user_ID, // This might be the same unless it's also being updated
        new_sponsor_id: sponsorId, // Newly selected user ID from dropdown
        new_team_id: "B1", // Example team ID, should be dynamic based on your requirements
        new_position: 1, // Example position, should be dynamic based on your requirements
        new_commission_rate: 6.92 // Example commission rate, should be dynamic based on your requirements
      }];

      console.log("Preparing to update MLM data:", updateData); // Logging the data to be sent

      const response = await fetch('https://py-maracar.afaa.website/mlm/api/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(updateData)
      });

      if (response.ok) {
        const result = await response.json();
        console.log('MLM update result:', result);
        setSubmitStatus({ success: true, message: 'MLM data updated successfully!' });
      } else {
        console.error('Failed to update MLM data');
        setSubmitStatus({ success: false, message: 'Failed to update MLM data.' });
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {mode === 'edit' && (
          <div className="mb-3">
            <label htmlFor="imageInput" className="form-label">Photo</label>
            <input type="file" className="form-control" id="imageInput" onChange={handleImageChange} />
            {previewImage && <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />}
          </div>
        )}

        {mode === 'add' && (
          <div className="mb-3 password-field">
            <label htmlFor="password" className="form-label">Password</label>
            <div className="input-group">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="current-password"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <i className={passwordVisible ? "pi pi-eye-slash" : "pi pi-eye"}></i>
              </button>
            </div>
          </div>
        )}

        <div className="mb-3">
          <label htmlFor="userDropdown">
            Sponsor {selectedUser && `: ${selectedUser.label}`}
          </label>
          {[3, 4].includes(positionID) ? (
            <Dropdown
              value={selectedUser ? selectedUser.value : null}
              options={users}
              onChange={handleUserChange}
              optionLabel="label"
              placeholder="Select a Sponsor"
              filter
              showClear
              filterBy="label"
              className="w-100"
            />
          ) : (
            <span>{selectedUser && `: ${selectedUser.label}`}</span>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">First Name</label>
          <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={handleChange} required />
        </div>

        <div className="mb-3">
          <label htmlFor="sur_name" className="form-label">Surname</label>
          <input type="text" className="form-control" name="sur_name" value={formData.sur_name} onChange={handleChange} />
        </div>

        <div className="mb-3">
          <label htmlFor="birthday" className="form-label">Birthday</label>
          <input type="date" className="form-control" name="birthday" value={formData.birthday} onChange={handleChange} required />
        </div>

        <div className="mb-3">
          <label htmlFor="contact" className="form-label">Address</label>
          <input type="text" className="form-control" name="contact" value={formData.contact} onChange={handleChange} />
        </div>

        <div className="mb-3">
          <label htmlFor="jangwatDropdown" className="form-label">Province</label>
          <Dropdown
            value={selectedJangwat}
            options={jangwats}
            onChange={handleJangwatChange}
            optionLabel="label"
            placeholder="Select a Province"
            filter
            showClear
            filterBy="label"
            className="w-100"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="amphureDropdown" className="form-label">District</label>
          <Dropdown
            value={selectedAmphure}
            options={amphures}
            onChange={handleAmphureChange}
            optionLabel="label"
            placeholder="Select a District"
            filter
            showClear
            filterBy="label"
            className="w-100"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="thamboonDropdown" className="form-label">Subdistrict</label>
          <Dropdown
            value={selectedThamboon}
            options={thamboons}
            onChange={handleThamboonChange}
            optionLabel="label"
            placeholder="Select a Subdistrict"
            filter
            showClear
            filterBy="label"
            className="w-100"
          />
        </div>


        <div className="mb-3">
          <label htmlFor="telephone" className="form-label">Telephone</label>
          <input type="tel" className="form-control" name="telephone" value={formData.telephone} onChange={handleChange} required />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
        </div>

        <div className="mb-3">
          <label htmlFor="social_media" className="form-label">Social Media</label>
          <input type="text" className="form-control" name="social_media" value={formData.social_media} onChange={handleChange} />
        </div>

        <div>
          <button type="submit" className="btn btn-success" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : mode === 'edit' ? 'Update Member' : 'Add Member'}
          </button>
          {isSubmitting && <Spinner />}
        </div>
      </form>

      {submitStatus.message && (
        <div className={`alert ${submitStatus.success ? 'alert-success' : 'alert-danger'}`}>
          {submitStatus.message}
        </div>
      )}
    </div>
  );
};

export default MemberForm;
