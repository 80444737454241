// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/store/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  positionID: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.positionID = action.payload.positionID;
    },
    clearUser: (state) => {
      state.user = null;
      state.positionID = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
