// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import CalendarView from '../components/CalendarView';
import DataQueues from '../components/DataQueues';
import MessagesPanel from '../components/MessagesPanel';
import DataGraph from '../components/DataGraph';
import Member from './Member';

import '../css/Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [refreshFlag, setRefreshFlag] = useState(false);


  const refreshData = () => {
    setRefreshFlag(prevFlag => !prevFlag); // Toggle to trigger useEffect in child components
  };

  const onDateChange = (newDate) => {
    setSelectedDate(newDate.split('T')[0]); // Update the selectedDate state based on the new date
  };


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  // Helper function to determine what to render
  const renderContent = () => {
    switch (location.pathname) {
      case '/Member':
        return <Member />;
      default:
        return (
          <>
            <MainContent />
            <CalendarView />
            <DataGraph />
            <MessagesPanel />
          </>
        );
    }
  };

  return (
    <div className="dashboard">
      {/* <Sidebar /> */}
      <div className="dashboard-main">
        <Header refreshData={refreshData} />

        <div className="content-area">
          {/* Render Member directly when the path is /Member */}
          {location.pathname === '/Member' && <Member />}
          {/* Otherwise, render the default Dashboard content */}
          {location.pathname !== '/Member' && (
            <>

              {/* <div className="main-content panels">
              <CalendarView  onDateClick={setSelectedDate} />
                
              </div> */}

              <div className="main-and-calendar">
              {/* <DataQueues refreshFlag={refreshFlag} selectedDate={selectedDate} onDateChange={onDateChange}/> */}
                <MainContent />
                {/* <CalendarView /> */}
              </div>

              <div className="main-content panels">
                <DataGraph />
                {/* <MessagesPanel /> */}
              </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
