// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/pages/Logout.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../store/userSlice';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const performLogout = async () => {
      const token = localStorage.getItem('token');

      // If token exists, call the logout API
      if (token) {
        try {
          await fetch('https://py.testproject.work/logout/api/checkout', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
        } catch (error) {
          console.error('Logout error:', error);
        }
      }

      // Remove token and user data from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('user_ID');
      localStorage.removeItem('first_name');
      localStorage.removeItem('sur_name');
      localStorage.removeItem('image');
      localStorage.removeItem('localBookingReduxID');

      // Clear Redux store
      dispatch(clearUser());

      // Redirect to login page
      navigate('/');
    };

    performLogout();
  }, [navigate, dispatch]);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
}

export default Logout;
